<template>
    <div class="my-5 mx-15">
        <h1 style="white-space:pre-wrap;" class="py-5 text-center text-lg-h2 text-sm-h3 text-xs-h4">Rekorde</h1>
        <v-tabs v-model="tab" :color="color('VfbBlau')" align-tabs="center">
            <v-tab v-for="(tab, i) in tabs" :key="i">
                {{ tab }}
            </v-tab>
            <v-tab-item v-for="(tab, i) in tabs" :key="i">
                <v-data-table :headers="headers(tab)" :items="items(tab)" :items-per-page="50"></v-data-table>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import { cardWidthHelper, cardHeightHelper } from '@/scripts/widthHelper'
import vfbRekorde from '@/assets/config/Vereinsrekorde VfB LC Friedrichshafen.json'
import { getColor } from '@/scripts/colorHelper'

export default {
  name: 'Rekorde',

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return cardHeightHelper(this.$vuetify.breakpoint.name)
    },
    tabs () {
      return Object.keys(vfbRekorde)
    }
  },
  methods: {
    headers (tab) {
      const jsonData = vfbRekorde[tab][0]
      const result = []
      for (const i in jsonData) { result.push({ text: i, value: i }) }
      return result
    },
    items (tab) {
      const jsonData = vfbRekorde[tab]
      const result = []
      for (const i in jsonData) {
        if (jsonData[i].Leistung !== '-') {
          result.push(jsonData[i])
        }
      }
      return result
    },
    color (name) {
      return getColor(name)
    }
  },
  data () {
    return {
    }
  }
}
</script>
