import Vue from 'vue'
import VueRouter from 'vue-router'
import training from '@/assets/config/training.json'
import Startseite from '../views/Startseite.vue'
import DerVerein from '../views/DerVerein.vue'
import DSGVO from '../views/DSGVO.vue'
import Erfolge from '../views/Erfolge.vue'
import Impressum from '../views/Impressum.vue'
import MitgliedWerden from '../views/MitgliedWerden.vue'
import OffeneStellen from '../views/OffeneStellen.vue'
import Trainer from '../views/Trainer.vue'
import Vorstand from '../views/Vorstand.vue'
import Training from '../views/Training.vue'
import Sportabzeichen from '../views/Sportabzeichen.vue'
import Rekorde from '../views/Rekorde.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'startseite',
    component: Startseite
  },
  {
    path: '/Bambini',
    name: 'bambini',
    component: Training,
    props: training[0]
  },
  {
    path: '/Kinder',
    name: 'kinder',
    component: Training,
    props: training[1]
  },
  {
    path: '/Jugend',
    name: 'jugend',
    component: Training,
    props: training[2]
  },
  {
    path: '/JugendUndAktive',
    name: 'jugendUndAktive',
    component: Training,
    props: training[3]
  },

  {
    path: '/LaufteamJugend',
    name: 'laufteamJugend',
    component: Training,
    props: training[4]
  },
  {
    path: '/LaufteamJugendUndAktive',
    name: 'laufteamJugendUndAktive',
    component: Training,
    props: training[5]
  },
  {
    path: '/Sportabzeichen',
    name: 'sportabzeichen',
    component: Sportabzeichen,
    props: training[6]
  },
  {
    path: '/60Plus',
    name: '60Plus',
    component: Training,
    props: training[7]
  },
  {
    path: '/PowerFitness',
    name: 'powerFitness',
    component: Training,
    props: training[8]
  },
  {
    path: '/DerVerein',
    name: 'rerVerein',
    component: DerVerein
  },
  {
    path: '/DSGVO',
    name: 'dsgvo',
    component: DSGVO
  },
  {
    path: '/Erfolge',
    name: 'erfolge',
    component: Erfolge
  },
  {
    path: '/Rekorde',
    name: 'rekorde',
    component: Rekorde
  },
  {
    path: '/Impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '/MitgliedWerden',
    name: 'mitgliedWerden',
    component: MitgliedWerden
  },
  {
    path: '/OffeneStellen',
    name: 'offeneStellen',
    component: OffeneStellen
  },
  {
    path: '/Trainer',
    name: 'trainer',
    component: Trainer
  },
  {
    path: '/Vorstand',
    name: 'vorstand',
    component: Vorstand
  }
]

const router = new VueRouter({
  routes
})

export default router
