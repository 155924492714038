<template>
  <div class="my-5">
    <v-card class="mx-auto" :max-width="width">
      <v-card-text style="white-space:pre-wrap;"
        class="text-justify text-md-body-1 text-xs-body-2 black--text">{{ text }}</v-card-text>
    </v-card>
  </div>
</template>
<script>
import { cardWidthHelper } from '@/scripts/widthHelper'

export default {
  name: 'TextField',

  props: ['text'],

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
    //
  })
}
</script>
