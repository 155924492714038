<template>
  <div class="my-5">
    <v-card elevation="" class="mx-auto" :max-width="width">
      <v-img contain class="white--text align-end" :height="height" :src=picture />
      <v-card-title class="justify-center text-xs-h5 text-lg-h4">
        {{ title }}
      </v-card-title>
      <v-card-text style="white-space:pre-wrap;" class="text-justify text-md-body-1 text-xs-body-2 black--text">{{ group }}
      </v-card-text>
      <v-card-text style="white-space:pre-wrap;"
          class="text-justify text-md-body-1 text-xs-body-2 black--text">{{ times }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :color="color('VfbBlau')" class="white--text" x-large :href="pdfFile" download>
          Alle Infos als PDF
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { cardWidthHelper, cardHeightHelper } from '@/scripts/widthHelper'
import { getColor } from '@/scripts/colorHelper'

export default {
  name: 'TrainingsgruppenCard',

  props: ['picture', 'title', 'group', 'pdfFile', 'times'],

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return cardHeightHelper(this.$vuetify.breakpoint.name)
    }
  },

  methods: {
    color (name) {
      return getColor(name)
    }
  },

  data: () => ({
    //
  })
}
</script>
