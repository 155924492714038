<template>
    <v-footer dark padless>
        <v-card flat tile width="100%" class="white--text text-center" :color="color('VfbBlau')">
            <v-card-text>
                <router-link class="white--text pa-2 text-body-1" style="text-decoration: none; color: inherit;"
                    :to="{ name: 'startseite' }">Startseite</router-link>
                <router-link class="white--text pa-2 text-body-1" style="text-decoration: none; color: inherit;"
                    :to="{ name: 'impressum' }">Impressum</router-link>
                <router-link class="white--text pa-2 text-body-1" style="text-decoration: none; color: inherit;"
                    :to="{ name: 'dsgvo' }">DSGVO</router-link>
            </v-card-text>
            <v-card-text>
                <v-btn icon href="https://www.instagram.com/vfb_lc_friedrichshafen/" target="_blank">
                    <v-icon size="24px" href="https://www.instagram.com/vfb_lc_friedrichshafen/">
                        {{ 'mdi-instagram' }}
                    </v-icon>
                </v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="white--text text-body-1">
                VfB LC Friedrichshafen, Teuringer Str. 2, 88045 Friedrichshafen
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { getColor } from '@/scripts/colorHelper'

export default {
  methods: {
    color (name) {
      return getColor(name)
    }
  },
  data: () => ({
    icons: [
      'mdi-instagram'
    ]
  })
}
</script>
