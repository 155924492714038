<template>
  <div>
    <h1 style="text-align: center;" class="mt-10"></h1>
    <TrainingsgruppenCard v-if="!pdfFile" class="mt-10" :picture="cardData.picture" :title="cardData.title"
      :group="cardData.group" :times="cardData.times" />
    <PdfCard v-if="pdfFile" class="mt-10" :picture="cardData.picture" :title="cardData.title" :group="cardData.group"
      :pdfFile="cardData.pdfFile" :times="cardData.times"/>
    <Banner v-if="showBanner" />
    <v-card class="mx-auto mb-4" :max-width="width">
      <v-card-title class="justify-center">
        <h2>Trainer/in</h2>
      </v-card-title>
      <v-row>
        <template v-for="(trainer) in trainerData">
          <v-col cols="12" md="6">
            <Person :picture="trainer.picture" :name="trainer.name" :email="trainer.email" />
          </v-col>
        </template>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import TrainingsgruppenCard from '@/components/article/TrainingsgruppenCard.vue'
import Person from '@/components/article/Person.vue'
import Banner from '@/components/navigation/banner.vue'
import { cardWidthHelper } from '@/scripts/widthHelper'
import PdfCard from '@/components/article/PdfCard.vue'

export default {
  name: 'TrainingView',

  components: {
    TrainingsgruppenCard,
    Person,
    Banner,
    PdfCard
  },

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },

  props: {
    cardData: {
      type: Object,
      required: false,
      default: null
    },
    trainerData: {
      type: Array,
      required: true
    },
    showBanner: {
      type: Boolean,
      required: false,
      default: false
    },
    pdfFile: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
