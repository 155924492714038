<template>
    <div class="my-5">
        <h1 style="white-space:pre-wrap;" class="py-5 text-center text-lg-h2 text-sm-h3 text-xs-h4">Stellenausschreibung
        </h1>
        <v-card elevation="" class="mx-auto py-5" :max-width="width">
            <v-img contain class="white--text align-end" :height="height" src="@/assets/VfB-Logo-T.png" />
            <v-card-title style="white-space:pre-wrap;" class="py-5 justify-center text-xs-h5 text-lg-h4">
                Nachwuchstrainer (m/w/d)
            </v-card-title>
            <v-card-subtitle style="white-space:pre-wrap;" class="py-5 text-center text-xs-subtitle-2 text-lg-subtitle-1">
                Anzeige des Hauptvereins
            </v-card-subtitle>
            <v-card-text class="pb-5 text-center text-md-body-1 text-xs-body-2 black--text">
                Wir freuen uns über Nachwuchstrainer, insbesondere für die Kindergruppen. Bei Interesse bitte unter <a
                    href="mailto:info@vfb-lc-fn.de">info@vfb-lc-fn.de</a> melden
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { cardWidthHelper, cardHeightHelper } from '@/scripts/widthHelper'

export default {
  name: 'OffeneStallen',

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return cardHeightHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
  })
}
</script>
