<template>
  <div class="my-5">
    <v-card elevation="" class="mx-auto" :max-width="width">
      <v-carousel height="auto" hide-delimiter-background delimiter-icon="mdi-minus" cycle :show-arrows="false">
        <v-carousel-item v-for="(item, i) in pictures" :key="i">
          <v-img :src="item.picture" :height="height"></v-img>
        </v-carousel-item>
      </v-carousel>
      <v-card-title class="justify-center text-xs-h5 text-lg-h4">
        {{ title }}
      </v-card-title>
      <v-card-text style="white-space:pre-wrap;" class="text-justify text-md-body-1 text-xs-body-2 black--text">{{ text
      }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cardWidthHelper, cardHeightHelper } from '@/scripts/widthHelper'

export default {
  name: 'ArtikelErfolge',

  props: ['pictures', 'title', 'text'],

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return cardHeightHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
    //
  })
}
</script>
