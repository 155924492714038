<template>
    <div>
        <h1 style="text-align: center;" class="my-10">Trainer/innen</h1>
        <v-card class="mx-auto mb-4" :max-width="width">
            <v-row>
                <v-col cols="12" md="6">
                    <VorstandCard :picture="vorstand1.picture" :name="vorstand1.name" :funktion="vorstand1.funktion" />
                </v-col>
                <v-col cols="12" md="6">
                    <VorstandCard :picture="vorstand2.picture" :name="vorstand2.name" :funktion="vorstand2.funktion" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <VorstandCard :picture="vorstand3.picture" :name="vorstand3.name" :funktion="vorstand3.funktion" />
                </v-col>
                <v-col cols="12" md="6">
                    <VorstandCard :picture="vorstand4.picture" :name="vorstand4.name" :funktion="vorstand4.funktion" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <VorstandCard :picture="vorstand5.picture" :name="vorstand5.name" :funktion="vorstand5.funktion" />
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import VorstandCard from '@/components/article/VorstandCard.vue'
import { cardWidthHelper } from '@/scripts/widthHelper'

export default {
  name: 'Trainer',

  components: {
    VorstandCard
  },
  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
    vorstand1: {
      picture: require('@/assets/LA_VfBLC_HP_22Jun2018_05806.jpg'),
      name: 'Robin Nitzer',
      funktion: 'Läufer Jugend/Aktive'
    },
    vorstand2: {
      picture: require('@/assets/LA_VfBLC_Portraits_5Nov2018_06542.jpg'),
      name: 'Simon Hettig',
      funktion: 'Leichtathletik Aktive'
    },
    vorstand3: {
      picture: require('@/assets/DSCF2147.jpeg'),
      name: 'Yelena Walser',
      funktion: 'Kinderleichtathletik'
    },
    vorstand4: {
      picture: require('@/assets/LA_VfBLC_SiggiJanischek_9Sep2019.jpg'),
      name: 'Siegfried Janischek',
      funktion: 'Jugendleichtathletik'
    },
    vorstand5: {
      picture: require('@/assets/LA_VfBLC_HP_22Jun2018_05817.jpg'),
      name: 'Semi Walker & \nMarkus Reithofer ',
      funktion: 'Sportabzeichen'
    }
  })
}
</script>
