<template>
  <v-card class="mx-auto pa-2 mt-5" :max-width="width">
    <div class="grid-container">
      <div v-for="(item, index) in training" :key="index" class="grid-item">
        <div class="grid-item-inner" @click="openRoute(item.cardData.to)" @mouseover="moveTextDown(index)"
          @mouseleave="resetTextPosition(index)">
          <img :src="item.cardData.picture" alt="Bild">
          <div :class="['text-container', { 'move-down': index === activeIndex }]">
            <span>{{ item.cardData.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { cardWidthHelper } from '@/scripts/widthHelper'
import { getColor } from '@/scripts/colorHelper'
import training from '@/assets/config/training.json'

export default {
  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },
  methods: {
    color (name) {
      return getColor(name)
    },
    moveTextDown (index) {
      this.activeIndex = index
    },
    resetTextPosition (index) {
      if (this.activeIndex === index) {
        this.activeIndex = -1
      }
    },
    openRoute (route) {
      this.$router.push(route)
    }
  },
  data () {
    return {
      training,
      gridItems: [],
      activeIndex: -1
    }
  }
}
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px;
}

.grid-item {
  position: relative;
  padding-bottom: 100%;
}

.grid-item-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  cursor: pointer;
}

.image-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Bilder passend skalieren, um das gesamte Element abzudecken */
}

.text-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 90, 170, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
  padding: 10px;
  color: #000;
  text-align: center;
  transition: transform 0.3s ease;
}

.text-container.move-down {
  transform: translateY(100%);
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
