<template>
    <div class="my-5">
        <v-card elevation="" class="mx-auto py-5" :max-width="width">
            <v-row>
                <v-card-title style="white-space:pre-wrap ;" class="mx-auto justify-center text-xs-h5 text-lg-h4">
                  Hauptversammlung:
                </v-card-title>
            </v-row>
            <v-row>
                <v-card-actions class="justify-center mx-auto">
                    <v-btn download :color="color('VfbBlau')" class="white--text" x-large
                        href="20241023_Einladung Hauptversammlung 2022-2023.pdf">
                        Zur Einladung
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { cardWidthHelper } from '@/scripts/widthHelper'
import { getColor } from '@/scripts/colorHelper'

export default {
  name: 'bannerMV',

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },
  methods: {
    color (name) {
      return getColor(name)
    }
  },

  data: () => ({
  })
}
</script>
