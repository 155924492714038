<template>
  <v-app>
    <NavBar />
    <v-main style="padding: 0">
      <router-view></router-view>
      <FooterMobile class="hidden-lg-and-up" />
      <FooterDesktop class="hidden-md-and-down" />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/navigation/NavBar.vue'
import FooterMobile from './components/footer/FooterMobile.vue'
import FooterDesktop from './components/footer/FooterDesktop.vue'

export default {
  name: 'App',

  components: {
    NavBar,
    FooterMobile,
    FooterDesktop
  },

  data: () => ({
    //
  })
}
</script>
