<template>
  <div class="">
    <v-card elevation="" class="mx-auto" :width="width">
      <v-img class="white--text align-end" :height="height" :src=picture />
      <v-card-title class="py-5 justify-center text-h6">
        {{ name }}
      </v-card-title>
      <v-card-text class="pb-5 text-center text-body-1 black--text">
        {{ funktion }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { personWidthHelper, personHeightHelper } from '@/scripts/widthHelper'

export default {
  name: 'VorstandCard',

  props: ['picture', 'name', 'funktion'],
  computed: {
    width () {
      return personWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return personHeightHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
    //
  })
}
</script>
