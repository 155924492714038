<template>
  <div class="my-5">
    <v-card class="mx-auto" :max-width="width">
      <v-carousel height="auto" hide-delimiter-background delimiter-icon="mdi-minus" cycle show-arrows-on-hover>
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-img :src="item.src" contain></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import { cardWidthHelper } from '@/scripts/widthHelper'

export default {
  props: ['items'],
  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },
  data () {
    return {
    }
  }
}
</script>
