<template>
  <v-parallax height="500" src="parallax.jpg">
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12">
        <h1 class="text-h2 font-weight-thin mb-4" :style="{ color: color('VfbBlau') }">
          Herzlich willkommen!
        </h1>
        <h4 class="text-h4 subheading" :style="{ color: color('VfbBlau') }">
          VfB Friedrichshafen | Leichtathletik
        </h4>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import { getColor } from '@/scripts/colorHelper'

export default {
  methods: {
    color (name) {
      return getColor(name)
    }
  },
  data () {
    return {
    }
  }
}
</script>
