<template>
    <div>
        <h1 style="text-align: center;" class="mt-10"></h1>
        <PdfCard class="mt-10" :picture="card.picture" :title="card.title" :group="card.group" :pdfFile="card.pdfFile" />
    </div>
</template>

<script>
import PdfCard from '@/components/article/PdfCard.vue'

export default {
  name: 'MitgliedWerden',

  components: {
    PdfCard
  },

  data: () => ({
    card: {
      picture: require('@/assets/VfB-Logo-T.png'),
      title: 'Mitglied Werden',
      group: 'Wir laden herzlich zum Probetraining ein und freuen uns immer über neue Mitglieder.  Den Aufnahmeantrag finden Sie hier:',
      pdfFile: 'MITGLIED.pdf'
    }

  })
}
</script>
