<template>
    <div class="my-5 hidden-md-and-down">
        <v-card class="mx-auto pb-2" :max-width="width">
            <v-card-title class="justify-center text-xs-h5 text-lg-h4">
                Rekorde
            </v-card-title>
            <v-row class="mx-auto justify-center pb-2">
                <Hexagon :size="widthHex" :border-size="4" :background-image="`url(vorszieleinlauf.jpg)`"
                    :border-color="color('VfbBlau')" class="hexagon-custom mx-2">
                    <span class="text-center mt-12">
                        <h3>Ringer, Richard</h3>
                        <h4>10000m</h4>
                        <h4>27:36,52 min</h4>
                    </span>
                </Hexagon>
                <Hexagon :size="widthHex" :border-size="4" :background-image="`url(vorszieleinlauf.jpg)`"
                    :border-color="color('VfbBlau')" class="hexagon-custom mx-2">
                    <span class="text-center mt-12">
                        <h3>Pauer, Nada Ina</h3>
                        <h4>1500m</h4>
                        <h4>4:19,00 min</h4>
                    </span>
                </Hexagon>
                <Hexagon :size="widthHex" :border-size="4" :background-image="`url(vorszieleinlauf.jpg)`"
                    :border-color="color('VfbBlau')" class="hexagon-custom mx-2">
                    <span class="text-center mt-12">
                        <h3>Janischek, Florian</h3>
                        <h4>Speer</h4>
                        <h4>71,32m</h4>
                    </span>
                </Hexagon>
            </v-row>
            <v-card-actions class="justify-center">
                <v-btn :color="color('VfbBlau')" class="white--text" x-large to="rekorde">
                    Alle Rekorde
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { cardWidthHelper } from '@/scripts/widthHelper'
import Hexagon from '@coddicat/vue-hexagon'
import { getColor } from '@/scripts/colorHelper'

export default {
  components: {
    Hexagon
  },
  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    },
    widthHex () {
      return cardWidthHelper(this.$vuetify.breakpoint.name) / 3 - 20
    }
  },
  methods: {
    color (name) {
      return getColor(name)
    }
  },
  data () {
    return {
    }
  }
}
</script>
