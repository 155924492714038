// width
export const cardWidthHelper = (breakpoint) => {
  switch (breakpoint) {
    // case 'xs': return 250
    // case 'sm': return 400
    case 'md': return 500
    case 'lg': return 600
    case 'xl': return 800
  }
}

export const personWidthHelper = (breakpoint) => {
  switch (breakpoint) {
    case 'xs': return 240
    case 'sm': return 380
    case 'md': return 380
    case 'lg': return 280
    case 'xl': return 380
  }
}

// height
export const cardHeightHelper = (breakpoint) => {
  switch (breakpoint) {
    // case 'xs': return 187
    // case 'sm': return 300
    case 'md': return 375
    case 'lg': return 450
    case 'xl': return 600
  }
}

export const personHeightHelper = (breakpoint) => {
  switch (breakpoint) {
    // case 'xs': return 300
    // case 'sm': return 500
    case 'md': return 500
    case 'lg': return 375
    case 'xl': return 500
  }
}
