<template>
    <div>
        <h1 style="text-align: center;" class="mt-10">Erfolge:</h1>
        <v-timeline class="hidden-md-and-down">
            <v-timeline-item v-for="(item, i) in articles" :key="i" fill-dot :color="color('VfbBlau')">
                <template v-slot:opposite>
                    <h2 :style="{ color: color('VfbBlau') }" v-text="item.date"></h2>
                </template>
                <div class="py-4">
                    <ArtikelErfolge :pictures="item.pictures" :title="item.title" :text="item.text" />
                </div>
            </v-timeline-item>
        </v-timeline>
        <div class="hidden-lg-and-up" v-for="(item, i) in articles" :key="i">
            <ArtikelErfolge :pictures="item.pictures" :title="item.title" :text="item.text" />
        </div>
    </div>
</template>

<script>
import ArtikelErfolge from '@/components/article/ArtikelErfolge.vue'
import articles from '@/assets/config/articles.json'
import { getColor } from '@/scripts/colorHelper'
export default {
  name: 'Erfolge',

  components: {
    ArtikelErfolge
  },
  methods: {
    color (name) {
      return getColor(name)
    }
  },

  data: () => ({
    articles
  })
}
</script>
