<template>
  <div>
    <h1 style="text-align: center;" class="mt-10">Impressum:</h1>
    <TextField :text="text" />
  </div>
</template>

<script>
import TextField from '@/components/article/TextField.vue'

export default {
  name: 'Impressum',

  components: {
    TextField
  },

  data: () => ({
    text: 'VfB LC Friedrichshafen\nTeuringer Str. 2 88045 Friedrichshafen\nE-Mail: info@vfb-lc-fn.de\nInhaltliche Verantwortlicher: Vorstand VfB LC Friedrichshafen. Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte wird jedoch keine Gewähr übernommen. Auf die Inhalte von verlinkten Seiten kann kein Einfluss genommen werden. Für diese fremden Inhalte wird keine Gewähr übernommen.'
  })
}
</script>
