<template>
  <div class="">
    <v-card elevation="" class="mx-auto" :width="width">
      <v-img class="white--text align-end" :height="height" :src=picture />
      <v-card-title style="white-space:pre-wrap;" class="py-5 text-h6 justify-center">
        {{ name }}
      </v-card-title>
      <v-card-text class="pb-5 justify-center text-body-1 black--text">
        <v-row>
          <v-col cols="12" xl="3">
            <h3 class="justify-center">E-Mail:</h3>
          </v-col>
          <v-col cols="12" xl="9">
            <a class="justify-center" :href="`mailto:${email}`">{{ email }}</a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { personWidthHelper, personHeightHelper } from '@/scripts/widthHelper'

export default {
  name: 'Person',

  computed: {
    width () {
      return personWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return personHeightHelper(this.$vuetify.breakpoint.name)
    }
  },

  props: ['picture', 'name', 'email'],

  data: () => ({
    //
  })
}
</script>
