<template>
    <div class="my-5">
        <v-card elevation="" class="mx-auto" :max-width="width">
            <v-img class="white--text align-end" :height="height" :src=picture />
            <v-card-title class="justify-center text-xs-h5 text-lg-h4">
                {{ title }}
            </v-card-title>
            <v-card-text class="text-justify text-h5 black--text">
                Die Gruppe:
            </v-card-text>
            <v-card-text style="white-space:pre-wrap;"
                class="text-justify text-md-body-1 text-xs-body-2 black--text">{{ group }}
            </v-card-text>
            <v-card-text class="text-justify text-h5 black--text">
                Trainingszeiten:
            </v-card-text>
            <v-card-text style="white-space:pre-wrap;"
                class="text-justify text-md-body-1 text-xs-body-2 black--text">{{ times }}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { cardWidthHelper, cardHeightHelper } from '@/scripts/widthHelper'

export default {
  name: 'TrainingsgruppenCard',

  props: ['picture', 'title', 'group', 'times'],

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    },
    height () {
      return cardHeightHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
    //
  })
}
</script>
