<template>
    <div>
        <h1 style="text-align: center;" class="mt-10"></h1>
        <PdfCard class="mt-10" :picture="card.picture" :title="card.title" :group="card.group" :pdfFile="card.pdfFile" />
        <v-card class="mx-auto mb-4" :max-width="width">
            <v-card-title class="justify-center">
                <h2>Trainer/in</h2>
            </v-card-title>
            <v-row>
                <v-spacer />
                <Person :picture="trainer1.picture" :name="trainer1.name" :email="trainer1.email" />
                <v-spacer />
            </v-row>
        </v-card>

    </div>
</template>

<script>
import Person from '@/components/article/Person.vue'
import PdfCard from '@/components/article/PdfCard.vue'
import { cardWidthHelper } from '@/scripts/widthHelper'

export default {
  name: 'Sportabzeichen',

  components: {
    Person,
    PdfCard
  },

  computed: {
    width () {
      return cardWidthHelper(this.$vuetify.breakpoint.name)
    }
  },

  data: () => ({
    card: {
      picture: require('@/assets/VfB-Logo-T.png'),
      title: 'Sportabzeichen',
      group: 'Das Sportabzeichen-Team unterstützt alle Kandidaten dabei, sich optimal auf die Prüfung im Bereich Leichtathletik vorzubereiten. In wöchentlichen Trainingseinheiten werden Anleitungen für die Disziplinen Lauf, Wurf und Sprung gegeben, um bestens vorbereitet die Prüfung antreten zu können.',
      pdfFile: 'SAZTermine2024_VfB.pdf'
    },
    trainer1: {
      picture: require('@/assets/LA_VfBLC_HP_22Jun2018_05817.jpg'),
      name: 'Semi Walker &\nMarkus Reithofer',
      email: 'sportabzeichen@vfb-lc-fn.de'
    }

  })
}
</script>
